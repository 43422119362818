<template>
	<div class="cbox">
		<div class="headbar">
			<div style="display: flex;">

				教师管理


				<el-button id="btnimport1" style="margin-left: 10px; " icon="el-icon-upload"
					@click="add">添加教师</el-button>

			</div>

		</div>
		<div class="bcontent">


			<el-table :data="DataList" stripe border>

				<el-table-column type="index" label="编号" width="80">
				</el-table-column>

				<el-table-column prop="loginName" label="登录名称" width="150">
				</el-table-column>
				<el-table-column prop="teacherName" label="教师姓名" width="150">
				</el-table-column>
				<el-table-column prop="phone" label="手机号码" width="150">
				</el-table-column>
				<el-table-column prop="deptName" label="所属系部" min-width="100">
				</el-table-column>
				<el-table-column prop="cdate" label="创建时间" width="160">
				</el-table-column>


				<el-table-column fixed="right" label="操作" width="200">
					<template slot-scope="scope">
						<el-button type="text" style="color: skyblue;" @click="updateDetail(scope.row)">编辑</el-button>
						<el-button type="text" style="color: orangered;" @click="remove(scope.row.id)">删除</el-button>
						<el-button type="text" style="color: orange;" @click="resetPwd(scope.row)">重置密码</el-button>


					</template>
				</el-table-column>



			</el-table>
		</div>
		<div style="padding-top: 10px;display: flex; flex-direction: row-reverse;">


		</div>




		<el-dialog title="教师编辑" :visible.sync="dialogVisible" :close-on-click-modal="false" width="800px">

			<div style="height: 60vh; overflow: auto;">
				<el-form ref="form" :model="EditItem" label-width="80px">

					<el-row>


						<el-col :span="20">
							<el-form-item label="登录名称">

								<el-input v-model="EditItem.loginName"></el-input>

							</el-form-item>
						</el-col>
						<el-col :span="20">
							<el-form-item label="教师姓名">

								<el-input v-model="EditItem.teacherName"></el-input>

							</el-form-item>
						</el-col>
						<el-col :span="20">
							<el-form-item label="手机号">

								<el-input v-model="EditItem.phone"></el-input>

							</el-form-item>
						</el-col>


						<el-col :span="20">
							<el-form-item label="登录密码" v-if="EditItem.id==0">

								<el-input v-model="EditItem.pwd"></el-input>

							</el-form-item>
						</el-col>

						<el-col :span="20">
							<el-form-item label="所属系部">

								<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
									@change="handleCheckAllChange">全选</el-checkbox>
								<el-checkbox-group v-model="EditItem.deptName">
									<el-checkbox :label="item.zhuanye" :value="item.zhuanye"
										v-for="(item,i) in zhuanyeList" :key="i">{{item.zhuanye}}</el-checkbox>

								</el-checkbox-group>


							</el-form-item>
						</el-col>



						<el-col :span="24">

						</el-col>
					</el-row>



				</el-form>

			</div>

			<div style="text-align: center; padding:10px;">

				<el-button type="primary" @click="saveForm">保存</el-button>
			</div>
		</el-dialog>





	</div>
</template>

<script>
	export default {
		components: {

		},
		data() {
			return {
				dialogVisible: false,
				isIndeterminate: true,
				checkAll: false,
				EditItem: {
					id: 0,
					loginName: "",
					teacherName: "",
					phone: "",
					pwd: "",
					deptName: []
				},
				DataList: [],
				keyword: "",
				page: {
					current_page: 1,
					next_page: 1,
					pre_page: 1,
					total_page: 1,
					count: 0
				},
				process: 0,
				zhuanyeList: []

			};
		},

		mounted() {
			if (sessionStorage.getItem("username") != "admin") {
				this.$alert('您无权访问', '提示', {
					confirmButtonText: '确定',
					callback: action => {

					}
				});
			} else {
				this.getList();
				this.getZhuanye()
			}



		},
		methods: {
			handleCheckAllChange(val) {
				this.EditItem.deptName = val ? val : [];
				this.isIndeterminate = false;
			},
			handleCheckedCitiesChange(value) {
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.zhuanyeList.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.zhuanyeList.length;
			},

			onSearch() {
				this.getList();
			},

			getList(page) {
				this.$http.post("/api/user_list", {
					keyword: this.keyword,
					page: this.page.current_page
				}).then(
					res => {
						this.DataList = res.data;


					})
			},
			getZhuanye() {
				this.$http.post("/api/zhuanye_select", {

				}).then(
					res => {
						this.zhuanyeList = res.data;


					})
			},

			paginate(val) {
				this.page.current_page = val
				this.getList()
			},

			add() {
				this.EditItem = {
					id: 0,
					loginName: "",
					teacherName: "",
					phone: "",
					pwd: "",
					deptName: []
				}
				this.process = 0
				this.dialogVisible = true
			},
			updateDetail(e) {
				let item = {
					...e
				}
				if (item.deptName) {
					item.deptName = item.deptName.split("|")
				} else {
					item.deptName = []
				}
				this.process = 0
				this.EditItem = item
				this.dialogVisible = true
			},
			handleVideoUpload(e) {

				this.EditItem.video_url = e.src
			},
			onProgress(e) {
				console.log(e);
				this.process = Math.round(e.loaded / e.total * 1000) / 10
			},
			handleCoverUpload(e) {

				this.EditItem.cover_url = e.src
			},

			remove(id) {
				this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post("/api/user_delete", {
						id: id
					}).then(res => {
						this.$message({
							type: 'success',
							message: '删除成功'
						});

						this.getList(this.page.current_page);
					})

				}).catch(() => {

				});
			},
			saveForm() {


				this.$http.post("/api/user_update", this.EditItem).then(res => {
					this.$message({
						type: 'success',
						message: '保存成功'
					});
					this.dialogVisible = false

					this.getList();
				})
			},
			resetPwd(row) {
				this.$prompt('请输入[' + row.loginName + ']新密码', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消'
				}).then(({
					value
				}) => {
					this.$http.post("/api/resetPwd", {
						pwd: value,
						id: row.id
					}).then(res => {
						this.$message({
							type: 'success',
							message: '重置成功'
						});
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消输入'
					});
				});
			}


		}
	}
</script>